<template>
  <div>
    <v-overlay :value="showOverlay"></v-overlay>

    <v-dialog
      v-model="sendSelectedMailDialogBool"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete <br /> Selected Customers?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text>Cancel</v-btn>
          <v-btn color="blue darken-1" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="sendMailDialogBool"
      max-width="500px"
    >
      <v-card v-if="maxCustomers > 5">
        <v-card-title class="headline">Ready to send?</v-card-title>
        <v-card-text>
          Your letters will be printed and sent right away and ${{mailChargeTotal}} will be billed to your account.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelMailSend()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="sendMailConfirm(item)">Send Mail</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="headline">Not Available in Free Trial.</v-card-title>
        <v-card-text>
          This feature is not available in free trial mode. Must upgrade to a paid subscription.
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelMailSend()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="sendMailConfirm(item)">Send Mail</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="customerList"
        item-key="email"
        show-select
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Customer List</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2 mr-2"
              @click="sendTextToSelected"
              v-if="selected.length > 0"
            >
              Text Selected
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mb-2 mr-2"
              @click="sendEmailToSelected"
              v-if="selected.length > 0"
            >
              Email Selected
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mb-2 mr-2"
              @click="sendMail()"
              v-if="selected.length > 0"
            >
              Mail Selected
            </v-btn>
          </v-toolbar>
        </template>
        
        <template v-slot:[`item.sendText`]="{ item }">
          <v-btn
            small
            class="mr-2"
            :disabled="!item.phone"
            @click="sendText(item)"
          >
            Text
          </v-btn>
        </template>
        <template v-slot:[`item.sendEmail`]="{ item }">
          <v-btn
            small
            class="mr-2"
            :disabled="!item.email"
            @click="sendEmail(item)"
          >
            Email
          </v-btn>
        </template>
         <template v-slot:[`item.sendMail`]="{ item }">
          <v-btn
            small
            class="mr-2"
            :disabled="!item.email"
            @click="sendMail(item)"
          >
            Mail
          </v-btn>
        </template>
        <template v-slot:[`item.slpAgreement`]="{ item }">
          <v-btn
            small
            class="mr-2"
            @click="openLink(item.pdfUrl)"
          >
            Preview Document
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="getCustomers"
          >
            Reset
          </v-btn>
        </template>
        <template 
          v-slot:item.date="{ item }"
        >
          <!-- <span>{{ new Date(item.date).toLocaleString() }}</span> -->
          <span> {{new Date(item.date).toDateString()}} </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { DB } from '../firebase/db'
import { mapState } from 'vuex'
import { Functions } from '../firebase/functions'
import axios from 'axios'

import _ from 'lodash'

const textSlpAgreement = Functions.httpsCallable('textSlpAgreement')
const emailSlpAgreement = Functions.httpsCallable('emailSlpAgreement')

// Added by HTMLPros
const emailSlpAgreementAdmin = Functions.httpsCallable('emailSlpAgreementAdmin')
const emailSlpAgreementAdminBulk = Functions.httpsCallable('emailSlpAgreementAdminBulk')

export default {
  name: 'CustomerList',
  data() {
    return {
      sortBy: 'date',
      sendMailDialogBool: false,
      sendSelectedMailDialogBool: false,
      sortDesc: true,
      tempItemStorage: null,
      showOverlay: false,
      companyName: '',
      serviceName: '',
      customerList: [],
      selected: [],
      mailChargeTotal: 0,
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },

        { text: 'Preview Document', value: 'slpAgreement', sortable: false },
        { text: 'Send Text', value: 'sendText', sortable: false },
        { text: 'Send Email', value: 'sendEmail', sortable: false },
        { text: 'Send Mail', value : 'sendMail', sortable: false },

        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'State', value: 'state' },
        { text: 'Zip', value: 'zip' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Number Of Services', value: 'numberOfServices' },
        { text: 'Percent Discount', value: 'percentDiscount' },
        { text: 'Regular Price', value: 'regularPrice' },
        { text: 'Level Pay Monthly Cost', value: 'levelPayMonthlyCost' },
        { text: 'Deadline', value: 'deadline' },
        { text: 'Agreement Sent Date', value: 'agreementSentDate' },
        { text: "Date Added", value: "date", dataType: "Date" }        
      ],
    }
  },
  computed: {
    ...mapState(['user', 'maxCustomers']),
  },
  methods: {
    getCustomers(){
      let docRef = DB.collection('user').doc(this.user.uid)
        docRef.get()
          .then((user) => {
            user = user.data()
            this.companyName = user.slpAgreement.companyName
            this.serviceName = user.slpAgreement.serviceName
            if (!user.customerList) {
              this.customerList = []
            } else {
              this.customerList = user.customerList
            }
          })
    },

    updateFirebase() {
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .set({
          customerList: this.customerList
        }, { merge: true })
        .then(() => {
          this.reloadCustomerComponent()
          this.getCustomers()
        })
        .catch((err) => {
          console.error('Error setting Document: ', err)
        })
    },

    reloadCustomerComponent() {
      this.$store.dispatch('iterateKey')
    },

    openLink(link) {
      window.open(link, '_blank')
    },

    async createTextPromise(item) {
      // console.log(item)
      const res = await axios
        .post('https://api-ssl.bitly.com/v4/shorten', {
          'long_url': item.pdfUrl
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_BITLY_INTEGRATION_TOKEN}`,
            'Content-Type': 'application/json'
          }
        })

      let shortedPdfUrl = _.get(res, 'data.link')

      return new Promise((resolve, reject) => {
        textSlpAgreement({
          companyName: this.companyName,
          phone: item.phone,
          pdfUrl: shortedPdfUrl,
        }).then(() => {
          resolve('Text Sent Successfully')
        }).catch(() => {
          reject('Text not sent :(')
        })
      })
    },

    createEmailPromise(item) {
      return new Promise((resolve, reject) => {
        emailSlpAgreement({
          companyName: this.companyName,
          serviceName: this.serviceName,
          email: item.email,
          pdfUrl: item.pdfUrl,
        }).then(() => {
          resolve('Email Sent Successfully')
        }).catch(() => {
          reject('Email not sent :(')
        })
      })
    },

    createEmailPromiseAdmin(item, purchaseInfo) {
      return new Promise((resolve, reject) => {
        emailSlpAgreementAdmin({
          pdfUrl: item.pdfUrl,
          purchaseInfo: purchaseInfo
        }).then(() => {
          resolve('Email Sent Successfully')
        }).catch(() => {
          reject('Email not sent :(')
        })
      })
    },

    sendText(item) {
      let p = this.createTextPromise(item)
      this.showOverlay = true

      p.then((message) => {
        this.showOverlay = false
        this.updateCustomerList(item)
        this.updateFirebase()
        alert(message)
      }).catch((message) => {
        this.showOverlay = false
        alert(message)
      })

    },

    sendEmail(item) {
      let p = this.createEmailPromise(item)
      this.showOverlay = true

      this.updateCustomerList(item)

      p.then((message) => {
        this.showOverlay = false
        this.updateCustomerList(item)
        this.updateFirebase()
        alert(message)
      }).catch((message) => {
        this.showOverlay = false
        alert(message)
      })

    },

    cancelMailSend() {
      this.sendMailDialogBool = false
      this.tempItemStorage = null
      this.selected = []
    },

    async sendMailConfirm() {
      this.customer = await DB.collection('user')
          .doc(this.user.uid)
          .collection('stripe')
          .doc('stripe_customer')
          .get()

      if (this.tempItemStorage) {
        this.sendMailConfirmSingleItem(this.customer.data().stripe_customer_id)
      } else if (this.selected) {
        this.sendMailConfirmBulk(this.customer.data().stripe_customer_id)
      } else {
        alert("Error: Item(s) to send not set.")
      }
    },

    sendMailConfirmSingleItem(stripeCustomerId) {
        let purchaseInfo = {
            priceId: 'price_1LRtA8D7TGVtVh1buuzk68TZ',
            quantity: 1,
            customer: stripeCustomerId
          }

        let emailSlpAgreementToAdminForMailingPromise = this.createEmailPromiseAdmin(this.tempItemStorage, purchaseInfo)

        this.sendSelectedMailDialogBool = false

        emailSlpAgreementToAdminForMailingPromise.then(() => {
          this.updateCustomerList(this.tempItemStorage)
          this.updateFirebase()
          this.tempItemStorage = null
          this.sendMailDialogBool = false
        }).catch(() => {
          this.showOverlay = false
          this.tempItemStorage = null
          this.sendMailDialogBool = false
        })
    },

    sendMailConfirmBulk(stripeCustomerId) {
      let purchaseInfo = {
          priceId: 'price_1LRtA8D7TGVtVh1buuzk68TZ',
          quantity: this.selected.length,
          customer: stripeCustomerId
        }

      let bulkEmailSlpAgreementsToAdminForMailingPromise = new Promise((resolve, reject) => {
        emailSlpAgreementAdminBulk({
          item: this.selected,
          purchaseInfo: purchaseInfo
        }).then(() => {
          resolve('Email Sent Successfully')
        }).catch(() => {
          reject('Email not sent :(')
        })
      })

      bulkEmailSlpAgreementsToAdminForMailingPromise.then(() => {
        _.forEach(this.selected, (item) => {
          this.updateCustomerList(item)
        })
        this.updateFirebase()
        this.selected = []
        this.showOverlay = false
        this.tempItemStorage = null
        this.sendMailDialogBool = false
      }).catch(() => {
        this.selected = []
        this.showOverlay = false
        this.tempItemStorage = null
        this.sendMailDialogBool = false
      })
    },

    async sendMail(item) {
      if (!item && this.selected) {
        this.mailChargeTotal = (335 / 100 * this.selected.length).toFixed(2)
      } else {
        this.mailChargeTotal = (335 / 100).toFixed(2)
        this.tempItemStorage = item
      } 
      this.sendMailDialogBool = true
    },

    sendTextToSelected() {
      let promises = []
      this.showOverlay = true

      _.forEach(this.selected, (customer) => {
        promises.push(this.createTextPromise(customer))
        this.updateCustomerList(customer)
      })

      Promise.all((promises)).then(() => {
        this.showOverlay = false
        alert('Success :)')
        this.selected = []
        this.updateFirebase()
      }).catch(() => {
        this.showOverlay = false
        alert('There was an error :(')
        this.selected = []
      })
    },

    updateCustomerList(customer) {
      let currentDate = new Date()
      let index = _.findIndex(this.customerList, (cus) => {
        return (customer.firstName === cus.firstName && customer.lastName === cus.lastName && customer.email === cus.email)
      })

      this.customerList[index].agreementSentDate = currentDate.toDateString()
    },

    sendEmailToSelected() {
      let promises = []
      this.showOverlay = true

      _.forEach(this.selected, (customer) => {
        promises.push(this.createEmailPromise(customer))
        this.updateCustomerList(customer)
      })

      this.updateFirebase()

      Promise.all((promises)).then(() => {
        this.showOverlay = false
        alert('Success :)')
        this.selected = []
        this.updateFirebase()
      }).catch(() => {
        this.showOverlay = false
        alert('There was an error :(')
        this.selected = []
      })
    }
  },
  components: {},
  mounted() {
    this.getCustomers()
  },
}
</script>

<style scoped>

</style>