<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    "
    fluid
  >
    <status-bar></status-bar>
    <v-container>
      <v-row>
        <v-col>
          <SlpCustomerList v-if="pdfUrlLoaded" :key="key" />

          <v-card
            v-else
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              width="100%"
              type="article, article, article, article"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Footer></Footer>
  </v-container>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'
import SlpCustomerList from '@/components/SlpCustomerList.vue'

import { DB } from '../firebase/db'
import { Storage } from '../firebase/storage'

import { mapState } from 'vuex'
import _ from "lodash"

import jsPDF from 'jspdf'
import 'jspdf-autotable'


export default {
  name: 'SLPAgreement',
  data(){
    return {
      slpData: undefined,
      currentYear: new Date().getFullYear(),
      pageOne: undefined,
      pdf: undefined,
      customerList: undefined,
      dataURI: undefined,
      customerNum: 1,
      updatedCustomerList: [],
      key: 0,
      pdfUrlLoaded: false,
      newSlpAgreements: false,
    }
  },
  components: {
    SlpCustomerList,
    StatusBar,
    Footer,
  },
  computed: {
    ...mapState(['user']),
    contractYear() {
      let date = new Date()
      return date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear()
    },
    priorServiceYear() {
      return this.currentYear - 1
    },
    billDateOrdinal() {
      let i = this.slpData.billDate % 10
      if (i === 1 && i != 11) {
        return 'st'
      } else if (i === 2 && i != 12) {
        return 'nd'
      } else if (i === 3 && i != 13) {
        return 'rd'
      } else {
        return 'th'
      }
    }
  },
  methods: {
    getSLPData() {
      // Get SLP Data here
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .get()
        .then((user) => {
          user = user.data()
          this.slpData = user.slpAgreement
          this.customerList = user.customerList
          this.dataURI = user.afterPictureBase64
          this.slpData.updateSLPDataFlag = user.updateSLPDataFlag
          this.generatePdfs()
        }).catch((err) => {
          console.log(err)
        })
    },

    firstPaymentDate(deadlineString, billDate) {
      let deadlineArray = _.words(deadlineString)

      let updatedMonth = this.setFirstPaymentMonth(deadlineArray[1])

      let updatedYear = (updatedMonth === 'Jan') ? String(Number(deadlineArray[3]) + 1) : String(Number(deadlineArray[3]))

      let firstPaymentDate = updatedMonth + ' ' + billDate + ', ' + updatedYear

      return firstPaymentDate
    },

    lastPaymentDate(deadlineString, billDate) {
      let deadlineArray = _.words(deadlineString)

      let updatedMonth = deadlineArray[1]

      // let updatedYear = (updatedMonth === 'Dec') ? String(Number(deadlineArray[3])) : String(Number(deadlineArray[3]) + 1)
      let updatedYear = String(Number(deadlineArray[3]) + 1)
      console.log('updatedYear:', updatedYear)

      let lastPaymentDate = updatedMonth + ' ' + billDate + ', ' + updatedYear

      return lastPaymentDate
    },

    setFirstPaymentMonth(month){
      let updatedMonth
      if (month === 'Jan') {
        updatedMonth = 'Feb'
      } else if (month === 'Feb') {
        updatedMonth = 'Mar'
      } else if (month === 'Mar') {
        updatedMonth = 'Apr'
      } else if (month === 'Apr') {
        updatedMonth = 'May'
      } else if (month === 'May') {
        updatedMonth = 'Jun'
      } else if (month === 'Jun') {
        updatedMonth = 'Jul'
      } else if (month === 'Jul') {
        updatedMonth = 'Aug'
      } else if (month === 'Aug') {
        updatedMonth = 'Sep'
      } else if (month === 'Sep') {
        updatedMonth = 'Oct'
      } else if (month === 'Oct') {
        updatedMonth = 'Nov'
      } else if (month === 'Nov') {
        updatedMonth = 'Dec'
      } else if (month === 'Dec') {
        updatedMonth = 'Jan'
      }

      return updatedMonth
    },

    loggedIn() {
      this.$store.dispatch('loggedIn')
    },

    generateParagraph(pdfDocument, textArray, shiftY, lineHeight, shiftX) {
      let itr = 1
      let tempString = ''
      let y = 0
      let numCharsPerLine = 80

      let x = shiftX ? shiftX : 20

      for(let i = 0; i < textArray.length; i++) {
        tempString = tempString + textArray[i] + ' '

        if(i === textArray.length - 1 || tempString.length > numCharsPerLine + 6 && textArray[i + 1].length < 6 || tempString.length > numCharsPerLine && textArray[i + 1].length >= 6){
          y = shiftY + itr * lineHeight 
          pdfDocument.text(x, y, tempString)
          tempString = ''
          itr += 1
        }
      }

      return y
    },

    generateParagraphSmallText(pdfDocument, textArray, shiftY, lineHeight, shiftX) {
      let itr = 1
      let tempString = ''
      let y = 0

      let x = shiftX ? shiftX : 20

      for(let i = 0; i < textArray.length; i++) {
        tempString = tempString + textArray[i] + ' '

        if(i === textArray.length - 1 || tempString.length > 106 && textArray[i + 1].length < 8 || tempString.length > 100 && textArray[i + 1].length >= 8){
          tempString = tempString.replace('superScript', this.billDateOrdinal)
          y = shiftY + itr * lineHeight 
          pdfDocument.text(x, y, tempString)
          tempString = ''
          itr += 1
        }
      }

      return y
    },

    generateTitleText(pdfDocument, textArray, shiftY, lineHeight, shiftX) {
      let itr = 1
      let tempString = ''
      let y = 0

      let x = shiftX ? shiftX : 20

      for(let i = 0; i < textArray.length; i++) {
        tempString = tempString + textArray[i] + ' '

        if(i === textArray.length - 1 || tempString.length > 41 && textArray[i + 1].length < 4 || tempString.length > 37 && textArray[i + 1].length >= 4){
          y = shiftY + itr * lineHeight 
          pdfDocument.text(x, y, tempString)
          tempString = ''
          itr += 1
        }
      }

      return y
    },

    processPhone(phone){
      return '+1 (' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6, 10)
    },

    updateSLPDataFlag(){
      let tenantAccount = DB.collection("user").doc(this.user.uid)
      tenantAccount
        .update({
          updateSLPDataFlag: false
        })
        .then(() => {
          console.log('updateSLPDataFlag is called')
        })
        .catch((err) => {
          console.error('Error setting Document: ', err)
        })
    },
    
    async generatePdfs() {
      let shiftY = 40

      let pdfPromise = new Promise((resolve, reject) => {
        _.forEach(this.customerList, (customer) => {
          if (_.has(customer, 'pdfUrl') && !this.slpData.updateSLPDataFlag) {
    
          this.updatedCustomerList.push(customer)
          if (this.customerNum === this.customerList.length && this.newSlpAgreements) {
    
            let customerAccountRef = DB.collection("user").doc(this.user.uid)
            customerAccountRef
              .set({
                customerList: this.updatedCustomerList
              }, { merge: true })
              .then(() => {
                this.key += 1
                resolve(this.updatedCustomerList)
              })
              .catch((err) => {
                reject(err)
              })
          } else if (this.customerNum === this.customerList.length && !this.newSlpAgreements) {
            this.pdfUrlLoaded = true
            resolve(this.updatedCustomerList)
          } else {
            this.customerNum += 1
            return
          }
        } else {
            // console.log('this.slpData.updateSLPDataFlag: ',this.slpData.updateSLPDataFlag)
            this.newSlpAgreements = true
            let paraOne = _.split('Thank you for trusting ' + this.slpData.companyName + ' to provide services to you.' + ' We are offering a new level-pay option to our customers. Under this option, you would pay a set amount each month for twelve months, instead of being charged per service each time we provide ' + this.slpData.serviceName + ' service.', ' ')
            let paraTwo = this.slpData.serviceDescription.split(/[" *"]/)
            let paraThree = _.split('Your monthly payment is based on an average of ' + customer.numberOfServices + ' per year – and you’ll receive a ' + customer.percentDiscount + '% discount on that amount if you sign up by ' + customer.deadline + '. If you choose this payment option, your monthly ' + this.slpData.serviceName + ' level pay amount is: ', ' ')

            // Letter 
            // width: 215.9mm
            // height: 279.4mm

            // dataURIWidth = 26.5mm
            // Horizontal Margin: 20mm

            let dataURIWidth = 26.5
            // let dataURIHeight = 26.5
            let letterWidth = 215.9
            let letterHorizontalMargin = 24.3
            // let letterHeight = 279.4

            let doc = new jsPDF({
              orientation: 'p',
              unit: 'mm',
              format: 'letter',
              putOnlyUsedFonts:false,
              floatPrecision: 16
            })

            doc.setFont("helvetica")
            doc.setFontSize(21)

            doc.addImage(this.dataURI, letterWidth - dataURIWidth - letterHorizontalMargin, 15)

            // doc.text(70, 60, '12 Month Service Agreement')

            doc.setFontSize(12)

            doc.text(letterHorizontalMargin, 25, this.slpData.companyName)
            doc.text(letterHorizontalMargin, 30, this.slpData.address.street)
            doc.text(letterHorizontalMargin, 35, this.slpData.address.city + ', ' + this.slpData.address.state + ' ' + this.slpData.address.zip)

            doc.text(letterHorizontalMargin, shiftY + 20, customer.firstName + ' ' + customer.lastName)
            doc.text(letterHorizontalMargin, shiftY + 25, customer.address)
            doc.text(letterHorizontalMargin, shiftY + 30, customer.city + ', ' + customer.state + ' ' + customer.zip)
            doc.text(letterHorizontalMargin, shiftY + 57, 'Dear ' + customer.firstName + ':')

            let y1 = this.generateParagraph(doc, paraOne, shiftY + 61, 7, letterHorizontalMargin)

            y1 = y1 + 5

            let y2 = this.generateParagraph(doc, paraTwo, y1, 7, letterHorizontalMargin)

            y2 = y2 + 5

            let y3 = this.generateParagraph(doc, paraThree, y2, 7, letterHorizontalMargin)

            // AutoTable Docs
            // https://github.com/simonbengtsson/jsPDF-AutoTable
            doc.autoTable({
              valign: 'center',
              body: [
                [
                  'Normal Rate for Service', 
                  'Monthly invoice varies based on services completed.\nPayment due upon receipt during the year.', 
                  '$' + customer.regularPrice + ' per ' + this.slpData.serviceName 
                ],

                [{
                  content: 'ºLevel-Pay',
                  colSpan: 1
                  },
                  {
                  content: 'Same payment amount due on the ' + this.slpData.billDate + this.billDateOrdinal + ' of each month. First payment is due ' + this.firstPaymentDate(customer.deadline, this.slpData.billDate) + '. Last payment is due ' + this.lastPaymentDate(customer.deadline, this.slpData.billDate) + '.',
                  colSpan: 1,
                  styles: { halign: 'center' },
                  },
                  {
                  content: '$' + (Math.round(customer.regularPrice * customer.numberOfServices / 12).toFixed(2)) + ' per month \n\nReturn by ' + customer.deadline + ' for the ' + customer.percentDiscount + '% discount and pay:\n$'+ customer.levelPayMonthlyCost + ' per month!',
                  colSpan: 1
                  }
                ],

                [{
                  content: 'ºLevel-pay rates are calculated by multiplying the per service bid by an average of ' + customer.numberOfServices + ' ' + this.slpData.serviceName + ' per season.',
                  colSpan: 3,
                  styles: { halign: 'center' },
                }],

                [{
                  content: 'If you have questions about this bid, please contact us at '+ this.processPhone(this.slpData.businessPhone)+' or '+this.slpData.businessEmail+'.',
                  colSpan: 3,
                  styles: { halign: 'center' },
                }]
              ],
              startY: y3 + 10,
              // tableWidth: 100,
              margin: {
                left: letterHorizontalMargin,
                right: letterHorizontalMargin,
              }
            })

            doc.addPage()

            doc.setFontSize(18)
            let paraFour = _.split(this.slpData.companyName + ' | 12 Month Service Agreement', ' ')

            doc.addImage(this.dataURI, 20, 15)

            this.generateTitleText(doc, paraFour, 15, 10, 55) + 14
            let verticalSpace = 49

            doc.setFontSize(14)
            doc.text(20, verticalSpace + 3,  'Customer Name: ' + customer.firstName + ' ' + customer.lastName)
            doc.setFontSize(11)

            verticalSpace = verticalSpace + 10
            
            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 20, verticalSpace - 1)

            let paraFive = _.split('Yes! I would like to be on the ' + this.slpData.serviceName + ' Level Pay Option, with 12 equal monthly payments of $' + customer.levelPayMonthlyCost+ ' for the next 12 months beginning date of signing specified below.', ' ')
            verticalSpace = this.generateParagraph(doc, paraFive, verticalSpace - 4, 7, 30) + 5

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 30, verticalSpace - 1)

            // here
            doc.text(40, verticalSpace + 4, 'I authorize ' + this.slpData.companyName + ' to automatically charge my credit card ')
            doc.text(40, verticalSpace + 8, 'on the ' + this.slpData.billDate + this.billDateOrdinal + ' of each month.*')
            verticalSpace = verticalSpace + 12
            
            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 30, verticalSpace - 1)

            doc.text(40, verticalSpace + 4, 'I will send a check. Please send my invoice via:')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 40, verticalSpace - 1)

            doc.text(50, verticalSpace + 4, 'mail: _______________________________')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 40, verticalSpace - 1)

            doc.text(50, verticalSpace + 4, 'email: _______________________________')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 20, verticalSpace - 1)

            doc.text(30, verticalSpace + 4, 'I would like to be billed monthly for individual services provided in ' + this.contractYear + '.')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 30, verticalSpace - 1)

            // here
            doc.text(40, verticalSpace + 4, 'I authorize ' + this.slpData.companyName + ' to automatically charge my credit card ')
            doc.text(40, verticalSpace + 8, 'on the ' + this.slpData.billDate + this.billDateOrdinal + ' of each month.*')
            verticalSpace = verticalSpace + 12

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 30, verticalSpace - 1)

            doc.text(40, verticalSpace + 4, 'I will send a check. Please send my invoice via:')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 40, verticalSpace - 1)

            doc.text(50, verticalSpace + 4, 'mail: _______________________________')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 40, verticalSpace - 1)

            doc.text(50, verticalSpace + 4, 'email: _______________________________')
            verticalSpace = verticalSpace + 7

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 20, verticalSpace - 1)

            doc.text(30, verticalSpace + 4, 'No, I have decided not to contract with ' + this.slpData.companyName + '.')

            verticalSpace = verticalSpace + 20

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 93, verticalSpace - 5)

            doc.text(20, verticalSpace, '*Credit Card Billing Information (optional)')

            doc.text(100, verticalSpace, 'Please continue to use the same card info currently on file.')

            verticalSpace = verticalSpace + 7

            doc.text(20, verticalSpace, 'Name on Card:___________________________')
            
            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 114, verticalSpace - 5)
            doc.text(121, verticalSpace, 'Visa')

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 132, verticalSpace - 5)
            doc.text(139, verticalSpace, 'MasterCard')

            doc.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5Ec1jGoYMA0kJ2+SM9AgAgAUXHvBY5BD6QAAAABJRU5ErkJggg==', 161, verticalSpace - 5)
            doc.text(168, verticalSpace, 'Discover')

            verticalSpace = verticalSpace + 7

            doc.text(20, verticalSpace, 'Billing Address:___________________________ City:______________ ZIP:___________')

            verticalSpace = verticalSpace + 7

            doc.text(20, verticalSpace, 'Card Number:___________________________ Expiration:____ / ____ CVV:_______')

            doc.setFontSize(10)

            let finePrint = _.split('ºContract must be returned with payment for month in which received to qualify for discounted level-pay rate. If contract is received later than ' + customer.deadline + ', monthly level-pay cost will be $' + (Math.round(customer.regularPrice * customer.numberOfServices / 12).toFixed(2)) + '. Level-pay installments must be received by the '+this.slpData.billDate+'superScript monthly. Failure to pay installments on time may result in a reversion to per-service payments at full cost. If customer terminates service for any reason, customer forfeits discount and a retroactive calculation of year-to-date per-service costs will be calculated. Any difference in charges for actual services completed and level-pay installments made must be paid within 30 days. (If the difference is owed to the customer, '+this.slpData.companyName+' will pay a refund.)', ' ')

            verticalSpace = verticalSpace + 12

            verticalSpace = this.generateParagraphSmallText(doc, finePrint, verticalSpace, 5) + 12

            doc.setFontSize(12)

            doc.text(20, verticalSpace, 'Signature:__________________________________________ Date:_______________')

            let pdf = doc.output('blob')

            let uniqueString = _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9)) + _.toString(_.random(0, 9))

            let storageRef = Storage.ref(this.user.uid+'/customerPDFs/'+uniqueString+'.pdf')

            storageRef.put(pdf, { contentType: 'application/pdf'})
              .then(() => {
                storageRef.getDownloadURL()
                  .then((downloadURL) => {
                    customer.pdfUrl = downloadURL

                    this.updatedCustomerList.push(customer)
                    if (this.customerNum === this.customerList.length) {              

                      let customerAccountRef = DB.collection("user").doc(this.user.uid)
                      customerAccountRef
                        .set({
                          customerList: this.updatedCustomerList
                        }, { merge: true })
                        .then(() => {
                          this.key += 1
                          this.pdfUrlLoaded = true
                          resolve(this.updatedCustomerList)
                        })
                        .catch((err) => {
                          console.error('Error setting Document: ', err)
                        })
                    }
                    this.customerNum += 1
                  })
              })
          }
        })
      })
      await pdfPromise
      this.updateSLPDataFlag()
      this.slpData.updateSLPDataFlag = false
      this.pdfUrlLoaded = true
    }
  },
  mounted() {
    this.getSLPData()
  }
}
</script>

<style scoped>

</style>